<template>
  <div>
    <email-template
      class="absolute z-0 opacity-0 cursor-default"
      :id="emailTemplateId"
      ref="template"
      :link="link"
      :is-questionnaire="isQuestionnaire"
    />
    <button
      class="clipboard btn-outline-small flex tooltip"
      :data-clipboard-target="`#${emailTemplateId}`"
      content="Candidate instructions will be copied. You can paste in the email and send out to candidates."
      v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large'}"
    >
      <img
        src="@/assets/capacio/camp/copy.svg"
        alt=""
        class="mx-2"
      >
      <!-- <span class="tooltiptext text-xs">Candidate instructions will be copied. You can paste in the email and send out to candidates.</span> -->
      Copy link
    </button>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import { jsPDF as JSPDF } from 'jspdf'
import Clipboard from 'clipboard'

import EmailTemplate from '../components/EmailTemplate.vue'

export default {
  components: {
    EmailTemplate
  },
  props: {
    testBatteryId: {
      type: String,
      required: true
    },
    isQuestionnaire: Boolean,
    isTeamRating: Boolean
  },
  data () {
    return {
      showAlert: false,
      qrCodeData: null,
      clipboard: null,
      emailTemplateId: `e_${(Math.random() + 1).toString(36).substring(7)}`
    }
  },
  computed: {
    link () {
      if (this.isTeamRating) {
        return `${window.location.origin}/TeamRating/${this.testBatteryId}`
      } else if (this.isQuestionnaire) {
        return `${window.location.origin}/questionnaire/invite/${this.testBatteryId}`
      } else {
        return `${this.$APP_FRONTEND_URL}/invitation/${this.testBatteryId}`
      }
    }
  },
  methods: {
    async generateQrCode () {
      try {
        const data = await QRCode.toDataURL(this.link)
        const doc = new JSPDF()
        doc.setFontSize(16)
        doc.text('Instructions for testing with the Game Intelligence APP', 15, 10)
        doc.setFontSize(11)
        doc.text(`
        
The test examines your cognitive abilities. The test measure performance, time and quality. 
For optimal results follow these instructions;

- Use a mobile phone with a touch screen. (No laptop).
- Shut down all apps that are running and swipe them away.
- Turn off all notifications and incoming calls, not to be disturbed during the test.
- Perform the test during daytime.
- Set aside 50-60 minutes.
- Sit in an undisturbed environment not to be disturbed during the test.
- Be meticulous with instructions and exercises so that you really understand the tasks
- If any problems occur, note and email hello@giscreening.com
- At the top right of the first page, select language (English or Swedish)

Scan the QR code below to access the test. 
`, 15, 10)
        doc.addImage(data, 'JPEG', 50, 78, 100, 100)
        doc.save('QR.pdf')
      } catch (e) {
        console.log(e)
      }
    }

  },
  async mounted () {
    this.clipboard = new Clipboard('.clipboard')

    try {
      const data = await QRCode.toDataURL(this.link)
      this.qrCodeData = data
    } catch (e) {
      console.log('Could not create qr code')
    }
    // this.generateQrCode()
  }
}

</script>
