<template>
  <div class="z-50">
    <input
      type="checkbox"
      id="edit-modal"
      class="hidden modal-toggle"
      :checked="open"
    >
    <div
      class="modal"
    >
      <div
        class="modal-box bg-white"
        id="edit-modal-focus"
        style="min-width: calc(90% - 220px); padding: 3rem; margin-left: 200px;"
      >
        <div class="mb-8">
          <h4 class="page-titles">
            Edit team
          </h4>
          <p>Add, remove or edit team members. You can edit each team member by clicking on the field directly. </p>
        </div>

        <p>
          Team members (
          {{
            (rowsData.length === 0 ? 0 : rowsData.length) + '/' + this.maxTeamMembers
          }}
          )
        </p>
        <div class="table-wrapper">
          <table
            class="w-full border-collapse border-2 border-gray-300 border-spacing-0 overflow-hidden"
            style="border-radius: 10px;"
          >
            <thead>
              <tr>
                <th style="width: 2rem;" />
                <th>
                  <div class="headers">
                    Name
                  </div>
                </th>
                <th>
                  <div class="headers">
                    Email
                  </div>
                </th>
                <th>
                  <div class="headers">
                    Team Role
                  </div>
                </th>
                <th>
                  <div class="headers">
                    Position
                  </div>
                </th>
                <th>
                  <div class="headers">
                    Company
                  </div>
                </th>
                <th>
                  <div class="headers flex">
                    Business area
                  </div>
                </th>
                <th>
                  <div class="headers flex">
                    Department
                  </div>
                </th>
                <th /> <!-- Add button column -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.name"
                    placeholder="John Smith"
                    required
                  >
                </td>
                <td>
                  <input
                    type="email"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.email"
                    placeholder="example@email.com"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.teamRole"
                    placeholder="Leader"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.position"
                    placeholder="Developer"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.company"
                    placeholder="Company AB"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.division"
                    placeholder="Technology"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none focus:ringue-300"
                    v-model="formData.department"
                    placeholder="IT"
                    required
                  >
                </td>
                <td>
                  <button
                    class="addbutton"
                    @click="addMember"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
              <tr
                v-for="(rowData, index) in rowsData"
                :key="index"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <input
                    type="text"
                    :value="rowData.name"
                    @input="updateRowData(index, 'name', $event.target.value)"
                    class="editInput"
                  >
                </td>
                <td>
                  <input
                    type="email"
                    :value="rowData.email"
                    @input="updateRowData(index, 'email', $event.target.value)"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    required
                    class="editInput"
                  >
                </td>
                <td>
                  <input
                    type="text"
                    :value="rowData.teamRole"
                    @input="updateRowData(index, 'teamRole', $event.target.value)"
                    class="editInput"
                  >
                </td>
                <td>
                  <input
                    type="text"
                    :value="rowData.position"
                    @input="updateRowData(index, 'position', $event.target.value)"
                    required
                    class="editInput"
                  >
                </td>
                <td>
                  <input
                    type="text"
                    :value="rowData.company"
                    @input="updateRowData(index, 'company', $event.target.value)"
                    required
                    class="editInput"
                  >
                </td>
                <td>
                  <input
                    type="text"
                    :value="rowData.division"
                    @input="updateRowData(index, 'division', $event.target.value)"
                    class="editInput"
                    required
                  >
                </td>
                <td>
                  <input
                    type="text"
                    :value="rowData.department"
                    @input="updateRowData(index, 'department', $event.target.value)"
                    class="editInput"
                  >
                </td>
                <td>
                  <button
                    class="removebutton"
                    @click="removeMember(index)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-12 h-12"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex flex-col gap-2 m-2">
          <span
            v-if="showInputMsg"
            class="text-lg text-red-700"
          >* Please fill all input fields</span>
          <span
            v-if="showMemberMsg"
            class="text-lg text-red-700"
          >* Please add members to your team</span>
          <span
            v-if="showLimitMsg"
            class="text-lg text-red-700"
          >* Maximum number of team members reached.</span>
          <span
            v-if="formData.email && !isValidEmail(formData.email)"
            class="text-lg text-red-700"
          >* Please enter a valid email address</span>
        </div>
        <div class="flex flex-row justify-end">
          <div class="modal-action mr-10">
            <label
              for="my-modal-2"
              class="btn-secondary mt-8"
              @click="$emit('close')"
            >Cancel</label>
          </div>
          <div class="modal-action">
            <label
              for="my-modal-2"
              class="btn-primary mt-8"
              style="padding: 1.5rem 3rem"
              @click="updateTeam"
            >Save changes</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    open: {
      type: Boolean,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showInputMsg: false,
      showMemberMsg: false,
      showLimitMsg: false,
      formData: {
        name: '',
        email: '',
        teamRole: '',
        division: '',
        department: '',
        company: '',
        position: ''
      },
      rowsData: [],
      maxTeamMembers: 10
    }
  },
  methods: {
    isValidEmail (email) {
      // Regular expression to validate email format
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i
      return emailPattern.test(email)
    },

    addMember () {
      // Check if all input fields are filled
      if (Object.values(this.formData).every(value => value.trim() !== '')) {
        this.showInputMsg = false
        // Check if the maximum number of team members has been reached
        if (this.rowsData.length < this.maxTeamMembers) {
          // Add the current form data to the rowsData array
          this.rowsData.push({ ...this.formData })
        } else {
          this.showLimitMsg = true
        }
        this.showMemberMsg = false
        // Reset the form data after adding a row
        this.formData = {
          name: '',
          email: '',
          teamRole: '',
          division: '',
          department: '',
          company: '',
          position: ''
        }
      } else {
        this.showInputMsg = true
      }
    },
    removeMember (index) {
      this.rowsData.splice(index, 1)
    },
    updateRowData (index, field, value) {
      this.rowsData[index][field] = value
    },
    updateTeam () {
      console.log(this.candidates)
      // Check for removed team members
      const removedMembers = this.candidates.filter(candidate => !this.rowsData.some(row => row.user_id === candidate.user_id))

      for (const candidate of removedMembers) {
        this.$api.deleteTeamMember(candidate.id)
          .then(() => {
            // Handle successful deletion if needed
            console.log(`Successfully deleted team member with user_id: ${candidate.user_id}`)
          })
          .catch(error => {
            // Handle error if the deletion fails
            console.error(`Error deleting team member with user_id: ${candidate.user_id}`, error)
          })
      }

      // Check for edited team members
      const editedMembers = this.rowsData.filter((row) => {
        const candidate = this.candidates.find((c) => c.user_id === row.user_id)
        return (
          candidate &&
          (candidate.name !== row.name ||
            candidate.email !== row.email ||
            candidate.teamRole !== row.teamRole ||
            candidate.division !== row.division ||
            candidate.department !== row.department ||
            candidate.company !== row.company ||
            candidate.position !== row.position)
        )
      })

      for (const editedMember of editedMembers) {
        // Make an API call to update the edited member's data
        this.$api
          .updateTeamMember(editedMember.id, editedMember)
          .then(() => {
            // Handle successful update if needed
            console.log(`Successfully updated team member with user_id: ${editedMember.user_id}`)
          })
          .catch((error) => {
            // Handle error if the update fails
            console.error(`Error updating team member with user_id: ${editedMember.user_id}`, error)
          })
      }

      // Check for newly added team members and send them to the server
      const newMembers = this.rowsData.filter(row => !row.user_id)

      console.log({ newMembers })
      if (newMembers.length > 0) {
        const body = { campaignId: this.campaignId, teamMembers: newMembers }
        this.$api.createTeamMember(body)
          .then(response => {
          // Handle successful addition if needed
            console.log('Successfully added new team members:', response)
          })
          .catch(error => {
          // Handle error if the addition fails
            console.error('Error adding new team members:', error)
          })
      }

      this.$emit('close')
    },
    deepCopyArray (arr) {
      return arr.map((item) => ({ ...item }))
    }
  },
  computed: {
    ...mapGetters({
      candidates: 'TEAMSCOREBOARD/getCandidates'
    })
  },
  async mounted () {
    await this.$store.dispatch('TEAMSCOREBOARD/getCandidates', this.campaignId)
    this.rowsData = this.deepCopyArray(this.candidates)
  }

}

</script>
<style lang="scss" scoped>@import '../editteam.scss';</style>
