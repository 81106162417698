<template>
  <div
    class="wrapper campaigns"
  >
    <div class="title-wrap relative">
      <h3 class="page-titles">
        Manage Teams
      </h3>
      <div class="profile">
        <the-profile class="print:hidden" />
      </div>
    </div>
    <div class="flex flex-col">
      <div class="mb-16">
        <router-link
          class="btn-secondary create-camp-btn"
          :to="{name: 'CreateTeam'}"
        >
          Create team
        </router-link>
      </div>
      <h5 class="mb-5 font-medium">
        Teams list
      </h5>
      <p>Below are the teams you have created, you can view results for the campaigns or distribute to candidates. </p>
    </div>
    <div class="flex flex-col w-full h-full">
      <div class="flex flex-col my-8">
        <team-dropdown
          v-for="team, index in teams"
          :open-latest="index == 0 && !!$route.query.latest"
          :key="team.id"
          :team="team"
          @edit-team="editTeamCallback(team.id)"
        />
      </div>
      <div class="justify-center my-8 btn-group">
        <button
          v-for="page in currentPages[1]"
          :key="page"
          class="btn btn-outline"
          :class="{'btn-active': page === currentPages[0] +1 }"
          @click="nextPage(page-1)"
        >
          {{ page }}
        </button>
      </div>
    </div>
    <div
      v-for="id in Object.keys(showEditTeamModal)"
      :key="id"
    >
      <edit-team-modal
        v-if="showEditTeamModal[id]"
        @close="showEditTeamModal[id] = false"
        :campaign-id="id"
        :open="showEditTeamModal[id]"
      />
    </div>
    <input
      type="checkbox"
      id="instructions-modal"
      class="modal-toggle"
    >
    <div class="modal">
      <div class="max-w-6xl modal-box p-8">
        <h3 class="text-lg font-semibold mb-4 ml-4">
          Instructions for testing with the Capacio APP
        </h3>
        <email-template
          :is-questionnaire="false"
          :link="''"
          :modal="true"
        />
        <div class="modal-action">
          <label
            for="instructions-modal"
            class="btn-outline-small"
          >Close</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TeamDropdown from '../components/TeamDropdown.vue'
import EditTeamModal from '../components/EditTeamModal.vue'
import { mapGetters } from 'vuex'
import EmailTemplate from '../components/EmailTemplate.vue'
import TheProfile from '@/components/TheProfile.vue'

export default {
  components: { TeamDropdown, EmailTemplate, TheProfile, EditTeamModal },
  data () {
    return {
      showEditTeamModal: {}
    }
  },
  computed: {
    ...mapGetters({
      teams: 'TEAMS/getTeams', currentPages: 'TEAMS/getPages'
    })
  },
  methods: {
    async nextPage (page) {
      // update query
      this.$router.push({ name: 'TeamBrain', params: { page } })
      this.$store.commit('TEAMS/SET_CURRENT_PAGE', page)
      await this.$store.dispatch('TEAMS/getTeams')
    },
    editTeamCallback (id) {
      this.showEditTeamModal[id] = true
    },
    initShowEditTeamModal () {
      const d = {}
      for (const index in this.teams) {
        d[this.teams[index].id] = false
      }
      this.showEditTeamModal = d
    }
  },
  async created () {
    if (this.$route.params.page) {
      const parsed = parseInt(this.$route.params.page)
      if (!isNaN(parsed)) {
        this.$store.commit('TEAMS/SET_CURRENT_PAGE', parsed)
      }
    }

    await this.$store.dispatch('TEAMS/getTeams')
    this.initShowEditTeamModal()
  }
}
</script>
<style scoped>
@import "../campaigns.scss";
@import "../overview.scss";
</style>
